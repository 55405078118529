<template>
  <div class="my-lg-5 my-md-7 my-2">
    <div class="row align-items-center">
      <div class="col col-lg-6 d-none d-lg-block text-center">
        <img
          class="my-auto"
          src="@/assets/register-company-details.svg"
          alt="people with thumbs up signs"
        />
      </div>

      <div class="col col-md-9 col-lg-6 mx-auto">
        <!--                Step-1-->
        <div class="card d-lg-none bg-light shadow-sm py-3 mb-3">
          <div class="col d-flex d-none align-items-center">
            <!--                  Step Number-->
            <div
              class="mr-3 px-3 py-2 shadow-sm align-self-center badge badge-primary"
              style="font-size: 150%; border-radius: 10px"
            >
              1
            </div>
            <!--                  Step Number Text-->
            <div class="text-left">
              <span class="h4">Step 1</span>

              <!--                    Step Details-->
              <div class="text-muted">Company Details</div>
            </div>
          </div>
        </div>

        <!--        Content-->
        <div class="card shadow-sm">
          <div class="card-body">
            <div class="pb-3 mb-4 border-bottom">
              <div class="h4">Register - Company details</div>
              <span class="text-muted"
                >Already have an account?
                <router-link
                  :to="{ name: 'login' }"
                  class="text-primary font-weight-bold"
                  >Login</router-link
                ></span
              >
            </div>

            <Form
              @submit="registerCompany"
              v-slot="meta"
              :validation-schema="companySchema"
            >
              <div class="form-group">
                <label class="font-weight-bold">Company/Business Name</label>
                <input-field icon="building">
                  <template v-slot:input>
                    <Field
                      type="text"
                      name="name"
                      placeholder="Company Name"
                      :class="meta.errors.name ? 'is-invalid' : ''"
                    />
                    <ErrorMessage name="name" class="invalid-feedback" />
                  </template>
                </input-field>
              </div>

              <div class="form-group">
                <label class="font-weight-bold">Address</label>
                <input-field icon="map-pin">
                  <template v-slot:input>
                    <Field
                      type="text"
                      name="address"
                      placeholder="Address"
                      :class="meta.errors.address ? 'is-invalid' : ''"
                    />
                    <ErrorMessage name="address" class="invalid-feedback" />
                  </template>
                </input-field>
              </div>

              <div class="form-group">
                <label class="font-weight-bold">Country</label>
                <Dropdown
                  size="lg"
                  :options="countries"
                  name="departmentId"
                  :input-class="meta.errors.countryId ? 'is-invalid' : ''"
                  placeholder="Select a Country"
                  icon="globe"
                  v-model="countryId"
                  :fields="['id', 'name']"
                  filterable
                />
                <!--            Extra Field - hidden for validation-->
                <Field
                  name="countryId"
                  id="countryId"
                  :class="meta.errors.countryId ? 'is-invalid' : ''"
                  type="hidden"
                  v-model="countryId"
                />
                <ErrorMessage name="countryId" class="invalid-feedback" />
              </div>

              <div class="row mt-4 justify-content-center">
                <div class="col-sm-12 col-md-8">
                  <button class="btn-primary btn-lg-cus" :disabled="loading">
                    <span class="font-weight-bold">Continue </span>
                    <span
                      v-show="loading"
                      class="spinner-border spinner-border-sm"
                    ></span>
                  </button>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col">
                  <div v-if="message" class="alert alert-danger" role="alert">
                    {{ message }}
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./register.ts"></script>

<style scoped></style>
