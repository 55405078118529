import { Options, Vue } from "vue-class-component";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import AuthStore from "@/store/auth-store";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import { ICompany } from "@/types/Auth";
import { ICountry } from "@/types/Address";
import DropdownComponent from "@/components/DropdownComponent.vue";

@Options({
  name: "Register",
  components: {
    Dropdown: DropdownComponent,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const companySchema = yup.object().shape({
      name: yup
        .string()
        .required("Organisation/Company name is required!")
        .min(3, "Must be at least 3 characters!")
        .max(100, "Must be maximum 100 characters!"),
      address: yup.string().required("Address is required!"),
      countryId: yup
        .number()
        .moreThan(0, "Country is required!")
        .required("Country is required!")
        .max(15, "Must be maximum 15 characters!"),
    });

    return {
      successful: false,
      loading: false,
      message: "",
      companySchema,
    };
  },
  computed: {
    loggedIn() {
      return this.authStore.isSignedIn();
    },
  },
  mounted(): void {
    if (this.loggedIn) {
      this.$router.push("/admin/home");
    }
  },
})
export default class Register extends Vue {
  protected workForceService: WorkForceService = new WorkForceService();
  protected authStore = AuthStore;
  message = "";
  loading = false;
  countries: ICountry[] = [];
  countryId = 0;

  registerCompany(company: ICompany): void {
    this.message = "";
    this.loading = true;
    company.countryId = this.countryId;

    this.workForceService
      .postOrPut<void>(
        "/account/registerCompany",
        company as ICompany,
        undefined
      )
      .then((response: any) => {
        if (!response.isError) {
          this.loading = false;
          const data = JSON.parse(response.content);
          AuthStore.setRegOrgToken(data.OrgId);
          this.$router.push({
            name: "registerOrgAdmin",
          });
        } else if (response.isSuccess == false) {
          this.message = response.errors;
          AuthStore.removeRegOrgToken();
        } else {
          AuthStore.removeRegOrgToken();
          const errors = response.errors.errors;
          for (const key in errors) {
            this.message += key + ": " + errors[key][0] + "<br>";
          }
        }
      });
  }

  async initialize(): Promise<any> {
    await this.workForceService
      .get<ICountry[]>(`/countries`, false)
      .then((response: any) => {
        this.countries = response.content;
      });
  }

  async created(): Promise<any> {
    await this.initialize();
  }
}
