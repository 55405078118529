import AuthStore from "@/store/auth-store";
import AppConsts from "@/shared/application/work-force";
import WorkForce from "@/shared/application/work-force";
import store from "@/store";
import router from "@/router";

export default class WorkForceService {
  private static request<T>(
    method: string,
    url: string,
    data: any | string = "",
    isMultipart = false,
    loadingEnabled = true
  ): Promise<IRestResponseDto<T>> {
    let isBadRequest = false;
    let body = data === "" ? null : data;
    const headers: { [key: string]: string } = {
      Authorization: `Bearer ${AuthStore.getToken()}`,
      /*,
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
            Expires: '0'*/
    };

    if (data && isMultipart == false) {
      headers["Content-Type"] = "application/json";
      body = JSON.stringify(data);
    }

    if (isMultipart == true) {
      body = data;
    }

    if (loadingEnabled) {
      WorkForce.isLoading = true;
    }

    return fetch(AppConsts.baseApiUrl + url, {
      method,
      headers,
      body,
    } as any)
      .then((response: any) => {
        isBadRequest = !response.ok;
        if (response.status === 401) {
          AuthStore.removeToken();
          store.dispatch("removeToken");
          router.push({ path: "/account/login" });
        }

        return response.text();
      })
      .then((responseContent: any) => {
        let content: any;

        try {
          content = JSON.parse(responseContent);
        } catch (err) {
          content = responseContent;
        }

        if (content.time) {
          const response = {
            isSuccess: content.isSuccess,
            message: content.message,
            time: content.time,
            data: content.data,
            isError: !content.isSuccess,
            errors: !content.isSuccess ? content.message : null,
            content: !content.isSuccess ? null : content.data,
          };

          return response;
        }

        const response = {
          isError: isBadRequest,
          errors: isBadRequest ? content : null,
          content: isBadRequest ? null : content,
        } as IRestResponseDto<T>;

        return response;
      })
      .finally(() => {
        WorkForce.isLoading = false;
      });
  }

  get<T>(url: string, loadingEnabled = true): Promise<IRestResponseDto<T>> {
    return WorkForceService.request<T>("GET", url, "", false, loadingEnabled);
  }

  delete(url: string): Promise<IRestResponseDto<void>> {
    return WorkForceService.request<void>("DELETE", url);
  }

  put<T>(url: string, data: any | string): Promise<IRestResponseDto<T>> {
    return WorkForceService.request<T>("PUT", url, data);
  }

  post<T>(url: string, data: any | string): Promise<IRestResponseDto<T>> {
    return WorkForceService.request<T>("POST", url, data);
  }

  postOrPut<T>(
    url: string,
    data: any | string,
    id: string | undefined
  ): Promise<IRestResponseDto<T>> {
    if (id) {
      return WorkForceService.request<T>("PUT", url, data);
    }

    return WorkForceService.request<T>("POST", url, data);
  }

  postMulitPart<T>(
    url: string,
    data: any | string,
    isMultipart: boolean
  ): Promise<IRestResponseDto<T>> {
    return WorkForceService.request<T>("POST", url, data, isMultipart);
  }
}
